<template>
  <section id="hero">
   <div class="container">
        <div class="content">
            <h1 v-html="heroContent.title"></h1>
            <h3 v-html="heroContent.subtitle"></h3>
            <div v-html="heroContent.text"></div>
            <div class="button-section">
<!--                <a class="btn btn-primary" href="https://competitions.ecosim.hu/graphisoft-itech-challenge-hackathon-2024" rel="nofollow" target="_blank">
                    {{cloud.btn1}}
                </a>
              <a class="btn btn-primary" href="https://youtu.be/4lrRLCwEbno" rel="nofollow" target="_blank">
                {{cloud.btn5}}
              </a>-->
<!--                <a class="btn btn-secondary" href="https://g4e.ecosim.hu/itech_challenge_2022" rel="nofollow" target="_blank">-->
<!--                    {{cloud.btn3}}-->
<!--                </a>-->
<!--                <a class="btn btn-dark" href="https://teaser.itechchallenge.hu" rel="nofollow" target="_blank">-->
<!--                    {{cloud.btn4}}-->
<!--                </a>-->
            </div>
        </div>
      </div>
  </section>
  <section id="hackathon">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-html="competitionContent.text"></div>
<!--          <a href="https://join.slack.com/t/itechchalleng-mma3798/shared_invite/zt-2qht38lif-56TTTPnJ0qHUAwSP2IPmHA" target="_blank" class="btn btn-primary" >{{competitionContent.btn}}</a>-->
<!--          <div v-html="competitionContent.help"></div>-->
        </div>
      </div>
    </div>
  </section>
  <section id="competition">
    <div class="container">
      <h2 class="text-line gradient-text">{{competitionContent.title}}</h2>
      <div class="row">
        <div class="col-md mb-3">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <h4 v-html="competitionContent.title1"></h4>
              <div class="content" v-html="competitionContent.txt1"></div>
            </div>
          </div>
        </div>
        <div class="col-md mb-3">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <h4 v-html="competitionContent.title2"></h4>
              <div class="content" v-html="competitionContent.txt2"></div>
            </div>
          </div>
        </div>
        <div class="col-md mb-3">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <h4 v-html="competitionContent.title3"></h4>
              <div class="content" v-html="competitionContent.txt3"></div>
            </div>
          </div>
        </div>
        <div class="col-md mb-3">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <h4 v-html="competitionContent.title4"></h4>
              <div class="content" v-html="competitionContent.txt4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!--  <section id="timing">-->
<!--    <div class="container">-->
<!--        <h2 class="text-line gradient-text">{{timingContent.title}}</h2>-->
<!--        <div class="row">-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date1"></h3>-->
<!--            <p v-html="timingContent.text1"></p>-->
<!--          </div>-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date2"></h3>-->
<!--            <p v-html="timingContent.text2"></p>-->
<!--          </div>-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date3"></h3>-->
<!--            <p v-html="timingContent.text3"></p>-->
<!--          </div>-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date4"></h3>-->
<!--            <p v-html="timingContent.text4"></p>-->
<!--          </div>-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date5"></h3>-->
<!--            <p v-html="timingContent.text5"></p>-->
<!--          </div>-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date6"></h3>-->
<!--            <p v-html="timingContent.text6"></p>-->
<!--          </div>-->
<!--          <div class="col-md">-->
<!--            <div class="line"></div>-->
<!--            <h3 class="date" v-html="timingContent.date7"></h3>-->
<!--            <p v-html="timingContent.text7"></p>-->
<!--          </div>-->
<!--        </div>-->
<!--    </div>-->
<!--  </section>-->
  <section id="awards">
    <div class="container">
      <h2 class="text-line gradient-text">{{awardContent.title}}</h2>
      <div class="row">
        <div class="col-sm-4">
          <div class="award_img1">
            <img src="../assets/site/award1.png">
          </div>
        </div>
        <div class="col-sm-8">
          <div class="middle">
            <h3 class="orange">{{awardContent.title1}}</h3>
            <div class="award-description" v-html="awardContent.text1"></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="award_img2">
            <img src="../assets/site/award2.png">
          </div>
        </div>
        <div class="col-sm-8">
          <div class="middle">
            <h3 class="orange">{{awardContent.title2}}</h3>
            <div class="award-description" v-html="awardContent.text2"></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="award_img3">
            <img src="../assets/site/award3.png">
          </div>
        </div>
        <div class="col-sm-8">
          <div class="middle">
            <h3 class="orange">{{awardContent.title3}}</h3>
            <div class="award-description" v-html="awardContent.text3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Company :globalData="globalData" :companyContent="companyContent" :pageId="pageId"></Company>
  <section id="participants">
     <div class="container">
            <h2 class="text-line gradient-text">{{participantsContent.title}}</h2>
              <Participants :globalData="globalData"></Participants>
              <div class="content" v-html="participantsContent.txt"></div>
              <router-link class="btn btn-primary" :to="{name : 'Page_75_'+userLanguage}">
                {{participantsContent.btn}}
              </router-link>
        </div>
  </section>
</template>

<script>
import Company from '../components/Company.vue'
import Participants from '../views/Participants.vue'

export default {
  name: 'MainPage',
  props:['globalData','pageId'],
  components: {
   Company, Participants
  },
  data(){
    
    return{

    }
  },
  computed:{
    userLanguage(){
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      let contents = this.globalData.contents[language][this.pageId]

      this.globalData.init.head.pageId = this.pageId
      this.globalData.init.head.title = contents.head.title
      this.globalData.init.head.keywords = contents.head.keywords
      this.globalData.init.head.description = contents.head.description

      return contents
    },
    heroContent(){
      return this.contents.slogen
    },
    timingContent(){
      return this.contents.page.timing
    },
    cloud(){
      return this.contents.cloud
    },
    companyContent(){
      return this.contents.page.company
    },
    awardContent(){
      return this.contents.page.award
    },
    competitionContent(){
      return this.contents.page.competition
    },
    participantsContent(){
      return this.contents.page.participants
    }
  },
  watch:{
    pageId(){
      this.contents = this.globalData.contents[this.userLanguage][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    },
    userLanguage(){
      this.contents = this.globalData.contents[this.userLanguage][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  }
}
</script>
<style>
#hero{
  position: relative;
  background-color: #5f6062;
  background-image: url('../assets/bg/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 572px;
  margin: 100px 0 0 0;
}
#hero::before{
  position: absolute;
  content: "";
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  min-height: 100%;
}
#hero .container{
  padding: 100px 0 85px 0;
  color: #fff;
  position: relative;
}
#hero .container h1{
  color:#fff;
  margin-bottom: 30px;
    font-weight: 300;
    font-size: 3.75rem;
    line-height: 3.75rem;
}
#hero .content {
    position: relative;
    z-index: 100;
    margin: 0 0 0 100px;
}
#hero .content h1 span {
    font-family: 'Proxima Nova Bold',sans-serif;
}
#hero .content h2 {
  font-family: 'Proxima Nova Bold',sans-serif;
  color:#fd7e14;
  margin-bottom: 30px;
  font-weight: 300;
}
#hero .content p{
  max-width: 400px;
    max-height: 106px;
    margin-bottom: 20px;
    overflow: hidden;
    line-height: 1;
}
#hero .button-section .btn {
    margin-right: 1rem;
}
#timing .col-md-3 {
    color: #fff;
}
#hackathon{
  background-color: #eee;
}
#hackathon .container{
  font-size: 1.2rem;
  padding: 60px 0 60px 0;
}
#timing .container{padding:120px 0 130px 0;}
#timing .row{
  margin: auto -18px;
}
#timing .bg1 {
    background: url('../assets/bg/timing_1.png') no-repeat;
    background-size: cover;
    padding: 3rem 2rem;
    height: 100%;
}
#timing .bg2 {
    background: url('../assets/bg/timing_2.png') no-repeat;
    background-size: cover;
    padding: 3rem 2rem;
    height: 100%;
}
#timing .bg3 {
    background: url('../assets/bg/timing_3.png') no-repeat;
    background-size: cover;
    height: 100%;
    padding: 3rem 2rem;
}
#timing .bg4 {
    background: url('../assets/bg/timing_4.png') no-repeat;
    background-size: cover;
    height: 100%;
    padding: 3rem 2rem;
}
#timing h2{
  margin-bottom: 3rem;
}
#timing h3 {
    font-family: 'Proxima Nova Bold',sans-serif;
    font-size: 15px;
    margin-top: 6px;
    margin-bottom: 0;
}
#timing p{
  line-height: 1rem;
}
#timing .line{
  width: 100%;
  height: 7px;
  border-bottom: 2px solid #282828;
  border-left: 2px solid #282828;
  border-right: 2px solid #282828;
}
#awards{
  background-color: #eee;
}
#awards .container {
    padding: 120px 0 120px 0;
}
#awards h2{
  font-family: 'Proxima Nova Bold',sans-serif;
  position: relative;
  margin-bottom: 3rem;
}
#awards .row {
    margin-bottom: 3rem;
    background-color: #fff;
}
#awards .col-sm-4 img {
    margin: 0.5rem 0;
}
#awards .col-sm-8 {
    position: relative;
}
#awards .middle {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    margin-left: 2rem;
}
#awards h3 {
    font-family: 'Proxima Nova Semibold',sans-serif;
    font-size: 24px;
    margin-bottom: 1rem;
}
.orange {
    color: #ff6104;
}
#awards .award-description {
    font-size: 26px;
    line-height: 16px;
}
#awards p {
    margin-bottom: 20px;
}
#competition{
  background-color: #fff;
}
#competition h2{
  font-family: 'Proxima Nova Bold',sans-serif;
  position: relative;
  margin-bottom: 3rem;
}
#competition .container {
  padding: 120px 0 120px 0;
}
#competition .card {
    border: none;
}
#participants .container{
  padding: 350px 0 100px 0;
}
#participants .content{
  margin-bottom: 30px;
}
@media (max-width: 1030px) {
   /*  SLIDER  */
    #hero .container{padding:65px 0 65px 0;}
    #hero .content{margin: 0 0 0 10px;}
    /*  TIMING  */
    #timing .container{padding:65px 0 65px 0;}
    #timing .timing-box{margin-bottom:30px;}
    #timing .bg1,#timing .bg2,#timing .bg3,#timing .bg4 {padding: 2rem 1.5rem}
    /*  AWARDS  */
    #awards{background:none;}
    #awards .container{padding:60px 0 60px 0;}
    #awards .award1 .left,#awards .award3 .left{width:40%;}
    #awards .award1 .right,#awards .award3 .right{width:60%;}
    #awards .award2 .left{width:60%;}
    #awards .award2 .right{width:40%;}
    #awards img{max-width:100%;}
    #awards .award_img1:after{left:70px;border-width:56px 70px 0 0;}
    #awards .award_img2:after{right:63px;border-width:55px 0 0 52px;}
    #awards .award_img3:after{left:40px;border-width:34px 38px 0 0;}
    /*  PARTICIPANTS  */
    #participants .container{padding:60px 0 60px 0;}
    #participants.sub .container{padding:60px 0 60px 0;}
}
@media (max-width: 768px) {
    #hero{
      height: auto;
    }
    #hero .btn{
        margin-right: 0;
        margin-bottom: 1rem;
    }
    #hero .btn{
        display: inline;
    }
    #hero .content h1{font-size:40px;line-height:40px;}
    /*  TIMING  */
    #timing .container{padding-top:60px;padding-bottom:0px;}
    #timing .content{margin:0 0 30px 0;}
    #timing .col-md-3{
        height: 180px;
        margin-bottom: 2rem;
        text-align: center;}
    /*  AWARDS  */
    #awards p{margin-bottom:5px;line-height: 21px;}
    #awards .award1 .left, #awards .award3 .left,#awards .award2 .right{display:none;}
    #awards .award1 .right, #awards .award3 .right,#awards .award2 .left{width:100%;float:none;margin:0 0 20px 0;text-align:left;}
    /*  PARTICIPANTS  */
    #participants{background:none;background-color:#fff;}
    #participants .participant{float:none;margin:0 0 30px 0;}
    #participants .participant .twoRow{padding:20px 0 0 0;}
    #participants .btn-primary{display: inline;}
    #participants .participant .teamname{padding:0 0 10px 0;}
}
@media screen and (max-width: 576px) {
  #hero .content h1 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    #hero .container {
        padding: 30px 0 65px 0;
    }
    #hero .btn, #company .btn-secondary, #participants .btn-primary{
        display: inherit;
    }
    #awards .middle{
        margin-left: 0;
    }
    #awards h3{
        font-size: 22px;
    margin-bottom: .5rem;
    }
    #awards p{
        font-size: 18px;
        line-height: 20px;
    }
    #awards .middle{
        position: initial;
        top: initial;
        transform: none;
    }
}
</style>
